export default [
  {
    path: "/inspiration",
    name: "inspiration",
    component: () => import("@/views/data/inspiration/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Inspiration",
      breadcrumb: [
        {
          text: "Inspiration",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-inspiration/:slug",
    name: "detail-inspiration",
    component: () => import("@/views/data/inspiration/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Inspiration",
      navActiveLink: "inspiration",
      breadcrumb: [
        {
          text: "Detail Inspiration",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tanya-master",
    name: "tanya-master",
    component: () => import("@/views/data/tanya-master/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Tanya Master",
      breadcrumb: [
        {
          text: "Tanya Master",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-tanya-master/:id",
    name: "detail-tanya-master",
    component: () => import("@/views/data/tanya-master/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Tanya Master",
      navActiveLink: "tanya-master",
      breadcrumb: [
        {
          text: "Detail Tanya Master",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-activity",
    name: "user-activity",
    component: () => import("@/views/data/user-activity/index.vue"),
    meta: {
      auth: true,
      pageTitle: "User Activity",
      breadcrumb: [
        {
          text: "User Activity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-list",
    name: "user-list",
    component: () => import("@/views/data/user-list/index.vue"),
    meta: {
      auth: true,
      pageTitle: "User List",
      breadcrumb: [
        {
          text: "User List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-login",
    name: "user-login",
    component: () => import("@/views/data/user-login/index.vue"),
    meta: {
      auth: true,
      pageTitle: "User Login",
      breadcrumb: [
        {
          text: "User Login",
          active: true,
        },
      ],
    },
  },

  /**
   *
   * vendor
   */
  {
    path: "/vendor-list",
    name: "vendor-list",
    component: () => import("@/views/data/vendor-list/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Vendor List",
      breadcrumb: [
        {
          text: "Vendor List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-vendor/:slug",
    name: "detail-vendor",
    component: () => import("@/views/data/vendor-list/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Vendor",
      navActiveLink: "vendor",
      breadcrumb: [
        {
          text: "Detail Vendor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-konsultasi",
    name: "vendor-konsultasi",
    component: () => import("@/views/data/vendor-konsultasi/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Vendor Konsultasi",
      breadcrumb: [
        { 
          text: "Vendor Konsultasi", 
          active: true,
        },
      ],
    },
  },{
    path: "/detail-konsultasi-vendor/:slug",
    name: "detail-konsultasi-vendor",
    component: () => import("@/views/data/vendor-konsultasi/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Konsultasi Vendor",
      navActiveLink: "vendor",
      breadcrumb: [
        {
          text: "Detail Konsultasi Vendor",
          active: true,
        },
      ],
    },
  },

  /**
   *
   * aktivitas
   */
  {
    path: "/aktivitas",
    name: "aktivitas",
    component: () => import("@/views/data/aktivitas/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Aktivitas",
      breadcrumb: [
        {
          text: "Aktivitas",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-aktivitas",
    name: "create-aktivitas",
    component: () => import("@/views/data/aktivitas/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Aktivitas",
      breadcrumb: [
        {
          text: "Create Aktivitas",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-aktivitas/:slug",
    name: "detail-aktivitas",
    component: () => import("@/views/data/aktivitas/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail aktivitas",
      navActiveLink: "aktivitas",
      breadcrumb: [
        {
          text: "Detail Aktivitas",
          active: true,
        },
      ],
    },
  },

  /**
   *
   * banner content
   */
  {
    path: "/banner-content",
    name: "banner-content",
    component: () => import("@/views/data/banner-content/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Banner Content",
      breadcrumb: [
        {
          text: "Banner Content",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-banner-content",
    name: "create-banner-content",
    component: () => import("@/views/data/banner-content/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Banner Content",
      breadcrumb: [
        {
          text: "Create Banner Content",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-banner-content/:slug",
    name: "detail-banner-content",
    component: () => import("@/views/data/banner-content/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Banner Content",
      breadcrumb: [
        {
          text: "Detail Banner Content",
          active: true,
        },
      ],
    },
  },

  /**
   *
   * Rab
   */
  // building material
  {
    path: "/building-material/index",
    name: "building-material",
    component: () => import("@/views/data/building-material/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Building Material",
      breadcrumb: [
        {
          text: "Building Material",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material-create",
    name: "building-material-create",
    component: () => import("@/views/data/building-material/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Building Material",
      breadcrumb: [
        {
          text: "Building Material",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-building-material/:slug",
    name: "building-material-detail",
    component: () => import("@/views/data/building-material/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Building Material",
      breadcrumb: [
        {
          text: "Building Material",
          active: true,
        },
      ],
    },
  },

  // category
  {
    path: "/building-material/category",
    name: "building-material-category",
    component: () =>
      import("@/views/data/building-material-category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/create-category",
    name: "building-material-category-create",
    component: () =>
      import("@/views/data/building-material-category/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
    },
  },
  // category
  {
    path: "/building-material/category/:slug",
    name: "building-material-detail-category",
    component: () =>
      import("@/views/data/building-material-category/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/floor",
    name: "building-material-floor",
    component: () => import("@/views/data/floor/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Floor",
      breadcrumb: [
        {
          text: "Floor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/floor-create",
    name: "building-material-floor-create",
    component: () => import("@/views/data/floor/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Floor",
      breadcrumb: [
        {
          text: "Floor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/floor/:slug",
    name: "building-material-floor-detail",
    component: () => import("@/views/data/floor/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Floor",
      breadcrumb: [
        {
          text: "Floor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/room",
    name: "building-material-room",
    component: () => import("@/views/data/room/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Room",
      breadcrumb: [
        {
          text: "Room",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/room-create",
    name: "building-material-room-create",
    component: () => import("@/views/data/room/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Room",
      breadcrumb: [
        {
          text: "Room",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/room/:slug",
    name: "building-material-room-detail",
    component: () => import("@/views/data/room/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Room",
      breadcrumb: [
        {
          text: "Room",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/pembongkaran",
    name: "building-material-pembongkaran",
    component: () => import("@/views/data/pembongkaran/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Pembongkaran",
      breadcrumb: [
        {
          text: "Pembongkaran",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/pembongkaran-create",
    name: "building-material-pembongkaran-create",
    component: () => import("@/views/data/pembongkaran/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Pembongkaran",
      breadcrumb: [
        {
          text: "Pembongkaran",
          active: true,
        },
      ],
    },
  },
  {
    path: "/building-material/pembongkaran/:slug",
    name: "building-material-pembongkaran-detail",
    component: () => import("@/views/data/pembongkaran/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Pembongkaran",
      breadcrumb: [
        {
          text: "Pembongkaran",
          active: true,
        },
      ],
    },
  },

  // feedback
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("@/views/data/feedback/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Feedback",
      breadcrumb: [
        {
          text: "Feedback",
          active: true,
        },
      ],
    },
  },

  // points
  {
    path: "/points",
    name: "points",
    component: () => import("@/views/data/points/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Points",
      breadcrumb: [
        {
          text: "Points",
          active: true,
        }
      ]

    }
  },{
    path: "/points-transaction",
    name: "points-transaction",
    component: () => import("@/views/data/points-transaction/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Points Transaction",
      breadcrumb: [
        {
          text: "Points Transaction",
          active: true,
        }
      ]

    }  
  },{
    path: "/appreaciation-points",
    name: "appreaciation-points",
    component: () => import("@/views/data/points-appreciation/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Appreaciation Points",
      breadcrumb: [
        {
          text: "Appreaciation Points",
          active: true,
        }
      ]
    }
  }
];
